<template>
  <div class="fixed_margin">
    <h5>Create a New Spec</h5>

    <validation-observer
      ref="spec_item_form"
      tag="form"
    >
      <!--      <validation-provider-->
      <!--        #default="{ errors }"-->
      <!--        name="Specification Name"-->
      <!--        rules="required"-->
      <!--      >-->

      <!--        <label for="name">Specification name:</label>-->
      <!--        <b-form-input v-model="form.name" />-->
      <!--        <small class="text-danger">{{ errors[0] }}</small>-->
      <!--      </validation-provider>-->
      <br>
      <label for="select">Select Budget Group</label>
      <v-select
        v-model="form.budget_group"
        :clearable="false"
        :options="budget_group"
        label="name"
        :reduce="(option) => option.key"
        @input="clearSelects"
      />

      <div class="my-1">
        <validation-provider
          #default="{ errors }"
          name="Element to attach"
          rules="required"
        >
          <label for="select">Attach to Element</label>
          <v-select
            v-model="selected_item"
            :clearable="false"
            :options="
              form.budget_group === 'CNST'
                ? cnst
                : form.budget_group === 'FF&E'
                  ? ffe
                  : avit
            "
            :label="
              form.budget_group === 'CNST' ? 'construction_spec' : 'element_name'
            "
          />
          <small
            class="text-muted"
          >This Spec will appear as spec swap.</small>
          <br>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </div>
      <div
        v-if="selected_item!=null"
        class="row"
      >
        <div class="col-4" />
        <div class="col-4">
          <b>Base Spec</b>
        </div>
        <div class="col-4">
          <b>New Spec</b>
        </div>
        <div class="col-4 ">
          <b>Name</b>
        </div>
        <div class="col-4">
          <div :class="form.budget_group !== 'CNST'?'ml-1':''">
            {{ form.budget_group === 'CNST' ? selected_item.construction_spec : selected_item.element_name }}
          </div>
        </div>
        <div class="col-4 pt-0 mt-0">
          <validation-provider
            #default="{ errors }"
            name="Spec Name"
            rules="required"
          >
            <b-form-input v-model="form.name" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>
        <div
          v-if="form.budget_group !== 'CNST'"
          class="col-4"
        >
          <b>Manufacturer</b>
        </div>
        <div
          v-if="form.budget_group !== 'CNST'"
          class="col-4"
        >
          <div
            v-if="form.budget_group !== 'CNST'"
            class="ml-1"
          >
            <p>
              {{ selected_item.vendor }}
            </p>
          </div>
        </div>
        <div
          v-if="form.budget_group !== 'CNST'"
          class="col-4 my-0"
        >
          <div v-if="form.budget_group !== 'CNST'">
            <b-form-input
              v-model="form.manufacturer"
              placeholder="[OPEN SPEC]"
            />
          </div>
        </div>
        <div class="col-4 " :class="form.budget_group !== 'CNST'?'my-0 py-0':''">
         <b> Unit COGS</b>
        </div>
        <div class="col-4" :class="form.budget_group !== 'CNST'?'my-0 py-0':''">
          <div
            v-if="form.budget_group !== 'CNST'"
            class="ml-1"
          >
            <p>
              {{
                Number(selected_item.unit_cost).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </p>
          </div>
          <div v-else>
            <p>
              {{
                Number(selected_item.unit_price).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </p>
          </div>
        </div>
        <div class="col-4 my-0 py-0">
          <div :class="form.budget_group !== 'CNST'?'mb-1':''">
            <validation-provider
                #default="{ errors }"
                name="Unit COGS"
                rules="required"
            >
              <b-input-group prepend="$">
                <b-form-input
                    v-model="form.cogs_per_unit"
                    placeholder="0"
                    type="number"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </div>
        </div>
        <div
          v-if="form.budget_group !== 'CNST'"
          class="col-4 my-0 py-0"
        >
          <b>Markup</b>
        </div>
        <div
          v-if="form.budget_group !== 'CNST'"
          class="col-4 my-0 py-0"
        >
          <div class="ml-1">
            {{ Number(((selected_item.supply_price-selected_item.unit_cost)/(selected_item.unit_cost))*100).toFixed(2) }} %
          </div>
        </div>
        <div
          v-if="form.budget_group !== 'CNST'"
          class="col-4 my-0 py-0"
        >
          <div v-if="form.budget_group !== 'CNST'">
            <validation-provider
                #default="{ errors }"
                name="Markup"
                :rules="{required:form.budget_group !== 'CNST'}"
            >
              <b-input-group append="%">
                <b-form-input
                    v-model="form.markup"
                    placeholder="0"
                    type="number"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </div>
        </div>
        <div class="col-4">
          <b>Unit Price</b>
        </div>
        <div class="col-4" :class="form.budget_group !== 'CNST'?'my-0 py-0':''">
          <div
            v-if="form.budget_group !== 'CNST'"
            class="col"
          >
            <p>
              {{
                Number(selected_item.supply_price).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </p>
          </div>
          <div v-else>
            <p>
              {{
                Number(selected_item.unit_price).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </p>
          </div>
        </div>
        <div class="col-4 my-0 py-0" :class="form.budget_group !== 'CNST'?'my-0 py-0':''">
          <div class="mt-1" v-if="form.budget_group !== 'CNST'">
            {{
              Number(total_price).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </div>
          <div v-else>
            <validation-provider
                #default="{ errors }"
                name="Unit Price"
                :rules="{required:form.budget_group === 'CNST'}"
            >
              <b-input-group prepend="$">
                <b-form-input
                    v-model="form.price_per_unit"
                    type="number"
                    step="0.01"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </div>
        </div>
        <div
          v-if="form.budget_group !== 'CNST'"
          class="col-4 my-0 py-0"
        >
          <b>D&I</b>
        </div>
        <div
          v-if="form.budget_group !== 'CNST'"
          class="col-4 my-0 py-0"
        >
          <div
            v-if="form.budget_group !== 'CNST'"
            class="col my-0 py-0"
          >
            <p>
              {{
                Number(selected_item.unit_delivery_w_install).toLocaleString(
                  "en-CA",
                  {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  }
                )
              }}
            </p>
          </div>
        </div>
        <div
          v-if="form.budget_group !== 'CNST'"
          class="col-4 my-0 py-0"
        >
          <div v-if="form.budget_group !== 'CNST'">
            <validation-provider
                #default="{ errors }"
                name="Delivery & Install"
                :rules="{required:form.budget_group !== 'CNST'}"
            >
            <b-input-group append="%">
              <b-form-input
                v-model="form.unit_delivery_install"
                placeholder="22.5"
                type="number"
              />
            </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </div>
        <div class="col-4">
         <b> Unit Price w/ D&I</b>
        </div>
        <div class="col-4">
          <div
            v-if="form.budget_group !== 'CNST'"
            class="col my-0 py-0"
          >
            <p>
              {{
                Number(selected_item.total_price).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </p>
          </div>
          <div v-else>
            <p>
              {{
                Number(selected_item.unit_price).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </p>
          </div>
        </div>
        <div class="col-4">
          <div>
            {{
              Number(total_price_w_delivery).toLocaleString("en-CA", {
                minimumFractionDigits: 2,
                style: "currency",
                currency: "CAD",
              })
            }}
          </div>
        </div>
      </div>
    </validation-observer>
    <div class="text-right">
      <b-button
        variant="primary"
        class="my-2"
        @click="addSpecificationItem"
      >Save Spec
      </b-button>
    </div>
    </validation-provider>

  </div>
</template>
<script>
import {
  BButton, BCol, BFormInput, BFormRadio, BFormTextarea, BInputGroup, BOverlay, BRow, BTable,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { UilGripHorizontalLine, UilTimes } from '@iconscout/vue-unicons'
import { required } from '@core/utils/validations/validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    BOverlay,
    BFormInput,
    BButton,
    BFormTextarea,
    vSelect,
    BCol,
    BRow,
    UilTimes,
    UilGripHorizontalLine,
    BInputGroup,
    BTable,
    BFormRadio,
    required,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    specItem: {
      type: Object,
    },
    base_items: {
      type: Object,
    },
  },
  data() {
    return {
      show: false,
      step: 1,
      budget_group: [
        { name: 'Construction', key: 'CNST' },
        { name: 'FF&E', key: 'FF&E' },
        { name: 'AV/IT', key: 'AVIT' },
      ],
      specification_type: 'reuse',
      form: {
        budget_group: this.specItem.budget_group,
        cogs_per_unit: this.specItem.cogs_per_unit,
        construction_work_id: this.specItem.construction_work_id,
        id: this.specItem.id,
        manufacturer: this.specItem.manufacturer,
        markup: this.specItem.markup,
        name: this.specItem.name,
        price_per_unit: this.specItem.price_per_unit,
        specification_swap_set_id: this.specItem.specification_swap_set_id,
        uid: this.specItem.uid,
        unit_delivery_install: this.specItem.unit_delivery_install,
      },
      emptyForm: {
        budget_group: 'CNST',
        cogs_per_unit: 0,
        construction_work_id: null,
        id: 1,
        manufacturer: null,
        markup: 10,
        name: null,
        price_per_unit: 0,
        specification_swap_set_id: 5,
        uid: null,
        unit_delivery_install: 22.5,
      },
      avit: this.base_items.avit,
      cnst: this.base_items.cnst,
      ffe: this.base_items.ffe,
      selected_item: this.specItem.budget_group === 'CNST' ? this.specItem.construction_work : this.specItem.element,
    }
  },
  computed: {
    total_price() {
      return Number(
        Number(this.form.cogs_per_unit) * (1 + (Number(this.form.markup) / 100)),
      )
    },
    total_price_w_delivery() {
      if (this.form.budget_group != 'CNST') {
        return Number(this.total_price * (1 + (this.form.unit_delivery_install / 100)))
      }
      return this.form.price_per_unit
    },
  },
  methods: {
    clearSelects() {
      this.selected_item = null
      this.form.cogs_per_unit = 0
      this.form.construction_work_id = null
      this.form.id = null
      this.form.manufacturer = null
      this.form.markup = 10
      this.form.price_per_unit = 0
      this.form.specification_swap_set_id = 5
      this.form.uid = null
      this.form.unit_delivery_install = 22.5
    },
    addSpecificationItem() {
      return new Promise((resolve, reject) => {
        this.$refs.spec_item_form.validate().then(success => {
          if (success) {
            if (this.form.budget_group !== 'CNST') {
              this.form.price_per_unit = this.total_price_w_delivery
              this.form.uid = this.selected_item.uid
              this.form.construction_work_id = null
            } else {
              this.form.construction_work_id = this.selected_item.id
              this.form.uid = null
              this.form.unit_delivery_install = 0
              this.form.markup = 0
            }
            this.$emit('saveItem', this.form)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style scoped>
.fixed_margin{
  margin-right: 15px;
  margin-top: 15px;
  margin-left: 15px;
}
</style>
